const Textarea = {
  variants: {
    outline: {
      backgroundColor: "white",
      _dark: { backgroundColor: "gray.700" }
    }
  }
};

export default Textarea;
